exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-academy-index-tsx": () => import("./../../../src/pages/academy/index.tsx" /* webpackChunkName: "component---src-pages-academy-index-tsx" */),
  "component---src-pages-academy-register-tsx": () => import("./../../../src/pages/academy/register.tsx" /* webpackChunkName: "component---src-pages-academy-register-tsx" */),
  "component---src-pages-academy-submitted-tsx": () => import("./../../../src/pages/academy/submitted.tsx" /* webpackChunkName: "component---src-pages-academy-submitted-tsx" */),
  "component---src-pages-academy-terms-tsx": () => import("./../../../src/pages/academy/terms.tsx" /* webpackChunkName: "component---src-pages-academy-terms-tsx" */),
  "component---src-pages-bank-direct-tsx": () => import("./../../../src/pages/bank-direct.tsx" /* webpackChunkName: "component---src-pages-bank-direct-tsx" */),
  "component---src-pages-brand-guidelines-tsx": () => import("./../../../src/pages/brand-guidelines.tsx" /* webpackChunkName: "component---src-pages-brand-guidelines-tsx" */),
  "component---src-pages-consumer-tsx": () => import("./../../../src/pages/consumer.tsx" /* webpackChunkName: "component---src-pages-consumer-tsx" */),
  "component---src-pages-contact-us-submitted-tsx": () => import("./../../../src/pages/contact-us-submitted.tsx" /* webpackChunkName: "component---src-pages-contact-us-submitted-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-merchant-index-tsx": () => import("./../../../src/pages/merchant/index.tsx" /* webpackChunkName: "component---src-pages-merchant-index-tsx" */),
  "component---src-pages-merchant-register-submitted-tsx": () => import("./../../../src/pages/merchant/register-submitted.tsx" /* webpackChunkName: "component---src-pages-merchant-register-submitted-tsx" */),
  "component---src-pages-merchant-register-tsx": () => import("./../../../src/pages/merchant/register.tsx" /* webpackChunkName: "component---src-pages-merchant-register-tsx" */),
  "component---src-pages-newsroom-tsx": () => import("./../../../src/pages/newsroom.tsx" /* webpackChunkName: "component---src-pages-newsroom-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-smartpay-shops-tsx": () => import("./../../../src/pages/smartpay-shops.tsx" /* webpackChunkName: "component---src-pages-smartpay-shops-tsx" */),
  "component---src-templates-faq-item-tsx": () => import("./../../../src/templates/FAQItem.tsx" /* webpackChunkName: "component---src-templates-faq-item-tsx" */),
  "component---src-templates-news-item-tsx": () => import("./../../../src/templates/NewsItem.tsx" /* webpackChunkName: "component---src-templates-news-item-tsx" */),
  "component---src-templates-redirection-tsx": () => import("./../../../src/templates/Redirection.tsx" /* webpackChunkName: "component---src-templates-redirection-tsx" */),
  "component---src-templates-support-tsx": () => import("./../../../src/templates/Support.tsx" /* webpackChunkName: "component---src-templates-support-tsx" */)
}

